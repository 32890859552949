import { defineStore } from 'pinia'
import {getMenuCodes} from "@/router/permission"
import {getPermissionDefaultMenus} from "@/config/permission"
import {cloneDeep} from "lodash-es";

export const PermissionStore = defineStore('permission', {
    state: () => ({
        hasRoutes: false,
        permissions: new Map()
    }),
    getters: {
        //
    },
    actions: {
        setRoutes() {
            this.hasRoutes = true
        },
        setPermission(permission: any) {
            const codes = permission || getMenuCodes(cloneDeep(getPermissionDefaultMenus()))
            codes.forEach((code: string) => {
                if (!this.permissions.has(code)) {
                    this.permissions.set(code, code)
                }
            })
        }
    }
});
