// @ts-nocheck
import { message } from 'ant-design-vue'
import { PageEnum } from '@/types/enums/pageEnum'
import { TokenName, CookieDomain, isHttps, SandboxModeKey } from '@/config/cookieKeys'
import cookies from 'js-cookie'
import { UserStore } from '@/stores/modules/user'
import { ProjectStore } from '@/stores/modules/project'
import {t} from "@/plugins/i18n";

export const isDev = process.env.NODE_ENV !== 'production'

/**
 * 获取token
 * @param {object} ctx - 服务端传入context 客户端传入this
 * @param {string} token - token
 */
export const getToken = () => {
  return cookies.get(TokenName) // , { expires }
};

export const removeToken = () => {
  return cookies.remove(TokenName, isDev ? {} : { domain: CookieDomain, path: '/' })
}

/**
 * 设置token'
 * @param {object} ctx - 服务端传入context 客户端传入this
 * @param {string} token - token
 */
export const setToken = (token: string) => {
  cookies.set(TokenName, token, isDev ? {
    secure: false,
    sameSite: 'lax',
    path: '/',
    expires: new Date(Date.now() + 8.64e7 * 7),
  } : {
    secure: !!isHttps,
    sameSite: 'lax',
    httpOnly: false,
    path: '/',
    domain: CookieDomain,
    expires: new Date(Date.now() + 8.64e7 * 7),
  })
};

/**
 * 设置登录验证信息
 * @param {object} ctx - 服务端传入context 客户端传入this
 * @param {object} res - 登录验证信息
 */
// export const setAuthInfo = (res: any) => {
//   if (res) {
//     cookies.set('userNo', res.user_no) // { expires }
//     cookies.set('userInfo', JSON.stringify(res))  //, { expires }
//   }
// };

/**
 * 移除登录验证信息
 * @param {object} ctx - 服务端传入context 客户端传入this
 */
export const removeAuthInfo = () => {
  cookies.remove('userInfo')
  cookies.remove('userId')
  cookies.remove(TokenName, isDev ? {} : { domain: CookieDomain, path: '/' })
  cookies.remove(SandboxModeKey)
};

/**
 * 退出登录
 * @param {object} ctx - 服务端传入context 客户端传入this
 */
export const loginOut = () => {
  const userStore = UserStore()
  userStore.clearUser()
  removeAuthInfo()
  sessionStorage.removeItem('isFirstLogin')
  window && (window.location.href = PageEnum.BASE_LOGIN)

};

export const dispatchProjectInfo = (pId: any) => {
  if (pId) {
    const projectStore = ProjectStore()
    projectStore.updateProject({
      id: pId,
    })
  } else {
    // eslint-disable-next-line no-console
    console.error('请传入context以及project_id')
  }
};

/**
 * 复制
 * @param {String} text - 需要复制的文本
 */
export const copyText = (text: string) => {
  if (!text) {
    return message.error('拷贝字符为空')
  }
  const type = "text/plain";
  const blob = new Blob([text], { type });
  // eslint-disable-next-line no-undef
  const data = [new ClipboardItem({ [type]: blob })];
  return new Promise((resolve, reject) => {
    navigator.clipboard.write(data)
      .then(() => {
        resolve('success')
      })
      .catch((e) => {
        message.error(e)
        reject(e)
      })
  })
}


export const to = (promise: any, errorExt?: any) => {
  return promise.then((data: any) => [null, data]).catch((err: any) => {
    if (errorExt) {
      const parsedError = Object.assign({}, err, errorExt)
      return [parsedError, undefined]
    }
    return [err, undefined]
  })
}

export const uploadCheck = (file, type = 'image') => {
  console.log(file.type)
  const maxSize = 2
  return new Promise((resolve, reject) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    const isLt1M = file.size / 1024 / 1024 <= maxSize
    if (!isJpgOrPng && type === 'image') {
      void message.error(t('upload.pic.tip5'))
      reject()
    } else if (type === 'sheet' && !file.type.includes('sheet')) {
      void message.error(t('upload.pic.tip7'))
      reject()
    } else if (!isLt1M) {
      void message.error(t('upload.pic.tip6',{ maxSize }))
      reject()
    } else {
      resolve()
    }
  })
}

export const rename = (name: any, removeFlags: any) => {
  let result = name
  removeFlags.forEach((item: any) => {
    result = result.replaceAll(item, '')
  })
  return result
}

export const locationFormError = () => {
  document.querySelector('.ant-form-item-has-error')?.scrollIntoView()
}


export function sleep(delay = 0) {
  return new Promise((resolve) => {
    const timer = setTimeout(() => {
      resolve()
      clearTimeout(timer)
    }, delay)
  })
}

export default {
  isDev,
  getToken,
  setToken,
  // setAuthInfo,
  removeAuthInfo,
  copyText,
  to,
}
