// @ts-nocheck
import {PageEnum, RouterNameEnum} from '@/types/enums/pageEnum'
import type {RouteRecordRaw} from 'vue-router'
import {PermissionCodeEnum} from "@/types/enums/permissionEnum"

export const baseRoutes: RouteRecordRaw[] = [
    {
        path: PageEnum.BASE_HOME,
        name: RouterNameEnum.BASE_HOME,
        meta: {
            title: 'kopglobal',
            icon: 'icon-home',
            desc: '首页',
            layout: null,
        },
        component: () => import('@/views/home/newIndex.vue'),
    },
    {
        path: PageEnum.BASE_LOGIN,
        name: RouterNameEnum.BASE_LOGIN,
        meta: {
            layout: 'loginLayout',
            title: '登录',
            icon: 'kopic-shouye'
        },
        component: () => import('@/views/login/index.vue'),
    },
    {
        path: PageEnum.BUSINESS_DETAIL,
        name: RouterNameEnum.BUSINESS_DETAIL,
        meta: {
            layout: 'dashboardLayout',
            title: '交易详情'
        },
        component: () => import('@/views/business/detail/_id.vue'),
    },
    {
        path: PageEnum.INVITE_MEMBER,
        name: RouterNameEnum.INVITE_MEMBER,
        meta: {
            layout: 'loginLayout',
            title: '邀请用户'
        },
        component: () => import('@/views/inviteMember/index.vue')
    },
    {
        path: PageEnum.USER_SETTING,
        name: RouterNameEnum.USER_SETTING,
        meta: {
            layout: 'dashboardLayout',
            title: '用户设置'
        },
        component: () => import('@/views/userSetting/index.vue')
    },
    {
        path: PageEnum.BASE_DASHBOARD,
        name: RouterNameEnum.BASE_DASHBOARD,
        meta: {
            layout: 'dashboardLayout',
            title: '首页看板',
        },
        component: () => import('@/views/dashboard/index.vue'),
    },
    {
        path: PageEnum.SERVICE_PRIVACY,
        name: RouterNameEnum.SERVICE_PRIVACY,
        component: () => import('@/views/service/privacy/index.vue'),
        meta: {
            title: '协议'
        }
    },
    {
        path: PageEnum.SERVICE_TERMS,
        name: RouterNameEnum.SERVICE_TERMS,
        component: () => import('@/views/service/terms/index.vue'),
        meta: {
            title: '协议'
        }
    },
    {
        path: PageEnum.SERVICE_REFUND,
        name: RouterNameEnum.SERVICE_REFUND,
        component: () => import('@/views/service/refund/index.vue'),
        meta: {
            title: '协议'
        }
    },
    {
        path: PageEnum.SERVICE_PAYMENT,
        name: RouterNameEnum.SERVICE_PAYMENT,
        component: () => import('@/views/service/payment/index.vue'),
        meta: {
            title: '支付方式'
        }
    },
    {
        path: PageEnum.PROJECT_ACTIVITY_REPLAY_LOTTERY_DETAIL,
        name: RouterNameEnum.PROJECT_ACTIVITY_REPLAY_LOTTERY_DETAIL,
        component: () => import('@/views/projectManage/project/_projectId/activityReplay/lotteryDetail/index.vue'),
        meta: {
            layout: 'dashboardLayout',
            title: '抽奖详情'
        }
    },
    {
        path: PageEnum.PROJECT_SITE_BUILD_EDITOR,
        name: RouterNameEnum.PROJECT_SITE_BUILD_EDITOR,
        component: () =>
            import('@/views/projectManage/project/_projectId/siteBuild/editor/_domain.vue'),
    },
    {
        path: PageEnum.PROJECT_SITE_BUILD_PREVIEW,
        name: RouterNameEnum.PROJECT_SITE_BUILD_PREVIEW,
        component: () =>
            import('@/views/projectManage/project/_projectId/siteBuild/preview/_domain/_lang.vue'),
    },
    {
        path: PageEnum.DOC,
        name: RouterNameEnum.DOC,
        component: () => import('@/views/doc/index.vue'),
    },
    {
        path: PageEnum.ABOUT,
        name: RouterNameEnum.ABOUT,
        component: () => import('@/views/about/index.vue'),
        meta: {
            title: '关于我们'
        }
    },
    {
        path: PageEnum.PROJECT_MANAGE_HOME,
        name: RouterNameEnum.PROJECT_MANAGE_HOME,
        redirect: PageEnum.PROJECT_ALL,
        children: [
            {
                path: PageEnum.PROJECT_ALL,
                name: RouterNameEnum.PROJECT_ALL,
                meta: {
                    layout: 'dashboardLayout',
                    title: '所有应用'
                },
                component: () => import('@/views/projectManage/projectAll/index.vue'),
            }
        ]
    },
    {
        path: PageEnum.PROJECT_GENERAL_SETTING,
        name: RouterNameEnum.PROJECT_GENERAL_SETTING,
        meta: {
            layout: 'dashboardLayout',
            title: '应用设置'
        },
        component: () => import('@/views/projectManage/project/_projectId/generalSetting/index.vue'),
    },
    {
        path: '/test',
        name: 'Test',
        component: () => import('@/views/test/index.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: RouterNameEnum.ERROR_PAGE,
        component: () => import('@/views/exception/index.vue'),
        meta: {
            title: 'Error'
        }
    }
]

export const routesList: RouteRecordRaw[] = []

export const RouterMap = new Map([
    [
        PermissionCodeEnum.Business,
        {
            path: PageEnum.BUSINESS,
            name: RouterNameEnum.BUSINESS,
            meta: {
                layout: 'dashboardLayout',
                title: '交易明细',
                caches: [RouterNameEnum.BUSINESS_DETAIL]
            },
            component: () => import('@/views/business/index.vue')
        }
    ],
    [
        PermissionCodeEnum.Settlement,
        {
            path: PageEnum.MERCHANT_SETTLEMENT,
            name: RouterNameEnum.MERCHANT_SETTLEMENT,
            meta: {
                layout: 'dashboardLayout',
                title: '结算'
            },
            component: () => import('@/views/merchantSettlement/index.vue')
        }
    ],
    [
        PermissionCodeEnum.BillReissue,
        {
            path: PageEnum.Bill_REISSUE,
            name: RouterNameEnum.Bill_REISSUE,
            meta: {
                layout: 'dashboardLayout',
                title: '漏单补发'
            },
            component: () => import('@/views/reissue/index.vue')
        }
    ],
    [
        PermissionCodeEnum.Chargeback,
        {
            path: PageEnum.CHARGEBACK,
            name: RouterNameEnum.CHARGEBACK,
            meta: {
                layout: 'dashboardLayout',
                title: '拒付'
            },
            component: () => import('@/views/chargeback/index.vue')
        }
    ],
    [
      PermissionCodeEnum.ReplacePayment,
        {
            path: PageEnum.REPLACE_PAYMENT,
            name: RouterNameEnum.REPLACE_PAYMENT,
            meta: {
                layout: 'dashboardLayout',
                title: '代付'
            },
            component: () => import('@/views/replacePayment/index.vue')
        }
    ],
    [
        PermissionCodeEnum.Bill,
        {
            path: PageEnum.BILL,
            name: RouterNameEnum.BILL,
            meta: {
                layout: 'dashboardLayout',
                title: '订单',
                caches: [RouterNameEnum.BILL_DETAIL]
            },
            component: () => import('@/views/bill/index.vue'),
        },
    ],
    [
        PermissionCodeEnum.BillDetail,
        {
            path: PageEnum.BILL_DETAIL,
            name: RouterNameEnum.BILL_DETAIL,
            meta: {
                layout: 'dashboardLayout',
                title: '订单详情'
            },
            component: () => import('@/views/bill/details/_id.vue'),
        }
    ],
    [
        PermissionCodeEnum.Analyze,
        {
            path: PageEnum.DATA_ANALYZE,
            name: RouterNameEnum.DATA_ANALYZE,
            meta: {
                layout: 'dashboardLayout',
                title: '分析'
            },
            component: () => import('@/views/dataAnalyze/index.vue')
        }
    ],
    [
        PermissionCodeEnum.AnalyzeStream,
        {
            path: PageEnum.DATA_ANALYZE_WATER,
            name: RouterNameEnum.DATA_ANALYZE_WATER,
            meta: {
                layout: 'dashboardLayout',
                title: '分析-流水'
            },
            component: () => import('@/views/dataAnalyze/water.vue')
        }
    ],
    [
        PermissionCodeEnum.AnalyzePayUser,
        {
            path: PageEnum.DATA_ANALYZE_PAYER,
            name: RouterNameEnum.DATA_ANALYZE_PAYER,
            meta: {
                layout: 'dashboardLayout',
                title: '分析-付费用户'
            },
            component: () => import('@/views/dataAnalyze/payer.vue')
        }
    ],
    [
        PermissionCodeEnum.AnalyzeRate,
        {
            path: PageEnum.DATA_ANALYZE_RATE,
            name: RouterNameEnum.DATA_ANALYZE_RATE,
            meta: {
                layout: 'dashboardLayout',
                title: '分析-转化率'
            },
            component: () => import('@/views/dataAnalyze/rate.vue')
        }
    ],
    [
        PermissionCodeEnum.AnalyzePaymentRank,
        {
            path: PageEnum.DATA_ANALYZE_RANK,
            name: RouterNameEnum.DATA_ANALYZE_RANK,
            meta: {
                layout: 'dashboardLayout',
                title: '分析-付费排行榜'
            },
            component: () => import('@/views/dataAnalyze/rank.vue')
        }
    ],
    [
        PermissionCodeEnum.AnalyzeVisits,
        {
            path: PageEnum.DATA_ANALYZE_VISIT,
            name: RouterNameEnum.DATA_ANALYZE_VISIT,
            meta: {
                layout: 'dashboardLayout',
                title: '分析-访问量'
            },
            component: () => import('@/views/dataAnalyze/visit.vue')
        }
    ],
    [
        PermissionCodeEnum.App,
        {
            path: PageEnum.PROJECT_MANAGE_HOME,
            name: RouterNameEnum.PROJECT_MANAGE_HOME,
            redirect: PageEnum.PROJECT_ALL,
            children: [
                {
                    path: PageEnum.PROJECT_INDEX,
                    name: RouterNameEnum.PROJECT_INDEX,
                    meta: {
                        layout: 'dashboardLayout',
                        title: '应用'
                    },
                    component: () =>
                        import('@/views/projectManage/project/_projectId/index.vue'),
                },
                {
                    path: PageEnum.PROJECT_ALL,
                    name: RouterNameEnum.PROJECT_ALL,
                    meta: {
                        layout: 'dashboardLayout',
                        title: '所有应用'
                    },
                    component: () => import('@/views/projectManage/projectAll/index.vue'),
                }
            ]
        }
    ],
    [
        PermissionCodeEnum.AppDetail,
        {
            path: PageEnum.PROJECT_GENERAL_SETTING,
            name: RouterNameEnum.PROJECT_GENERAL_SETTING,
            meta: {
                layout: 'dashboardLayout',
                title: '应用设置'
            },
            component: () => import('@/views/projectManage/project/_projectId/generalSetting/index.vue'),
        }
    ],
    [
        PermissionCodeEnum.VirtualGoods,
        {
            path: PageEnum.PROJECT_CLASS,
            name: RouterNameEnum.PROJECT_CLASS,
            meta: {
                layout: 'dashboardLayout',
                title: '虚拟物品'
            },
            component: () =>
                import('@/views/projectManage/project/_projectId/class/index.vue'),
        }
    ],
    [
        PermissionCodeEnum.GoodsClassify,
        {
            path: PageEnum.PROJECT_GOODS_CLASSIFY,
            name: RouterNameEnum.PROJECT_GOODS_CLASSIFY,
            meta: {
                layout: 'dashboardLayout',
                title: '商品分类'
            },
            component: () => import('@/views/projectManage/project/_projectId/goodsClassify/index.vue'),
        }
    ],
    [
        PermissionCodeEnum.GoodsManage,
        {
            path: PageEnum.PROJECT_GOODS_MANAGE,
            name: RouterNameEnum.PROJECT_GOODS_MANAGE,
            meta: {
                layout: 'dashboardLayout',
                title: '商品管理'
            },
            component: () => import('@/views/projectManage/project/_projectId/goodsManage/index.vue'),
        }
    ],
    [
        PermissionCodeEnum.ConvertKey,
        {
            path: PageEnum.PROJECT_CONVERT_KEY,
            name: RouterNameEnum.PROJECT_CONVERT_KEY,
            meta: {
                layout: 'dashboardLayout',
                title: '兑换码'
            },
            component: () => import('@/views/projectManage/project/_projectId/convertKey/index.vue'),
        }
    ],
    [
        PermissionCodeEnum.CoverKeyCreate,
        {
            path: PageEnum.PROJECT_CONVERT_KEY_CREATE,
            name: RouterNameEnum.PROJECT_CONVERT_KEY_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '生成兑换码'
            },
            component: () => import('@/views/projectManage/project/_projectId/convertKey/create.vue')
        }
    ],
    [
        PermissionCodeEnum.VirtualCurrency,
        {
            path: PageEnum.PROJECT_CURRENCY,
            name: RouterNameEnum.PROJECT_CURRENCY,
            meta: {
                layout: 'dashboardLayout',
                title: '商城币'
            },
            component: () =>
                import('@/views/projectManage/project/_projectId/currency/index.vue'),
        }
    ],
    [
        PermissionCodeEnum.VirtualActivity,
        {
            path: PageEnum.PROJECT_ACTIVITY,
            name: RouterNameEnum.PROJECT_ACTIVITY,
            meta: {
                layout: 'dashboardLayout',
                title: '营销活动'
            },
            component: () =>
                import('@/views/projectManage/project/_projectId/activity/index.vue'),
        }
    ],
    [
        PermissionCodeEnum.ActivityReplay,
        {
            path: PageEnum.PROJECT_ACTIVITY_REPLAY,
            name: RouterNameEnum.PROJECT_ACTIVITY_REPLAY,
            meta: {
                layout: 'dashboardLayout',
                title: '活动复盘'
            },
            component: () => import('@/views/projectManage/project/_projectId/activityReplay/index.vue')
        }
    ],
    [
        PermissionCodeEnum.VirtualActivityDetail,
        {
            path: PageEnum.PROJECT_ACTIVITY_DETAIL,
            name: RouterNameEnum.PROJECT_ACTIVITY_DETAIL,
            meta: {
                layout: 'dashboardLayout',
                title: '营销活动详情'
            },
            component: () =>
                import('@/views/projectManage/project/_projectId/activity/detail/_id/index.vue'),
        }
    ],
    [
        PermissionCodeEnum.Recharge,
        [
            {
                path: PageEnum.PROJECT_RECHARGE,
                name: RouterNameEnum.PROJECT_RECHARGE,
                meta: {
                    layout: 'dashboardLayout',
                    title: '充值'
                },
                component: () => import('@/views/projectManage/project/_projectId/recharge/index.vue')
            },
            {
                path: PageEnum.PROJECT_NEW_RECHARGE,
                name: RouterNameEnum.PROJECT_NEW_RECHARGE,
                meta: {
                    layout: 'dashboardLayout',
                    title: '充值'
                },
                component: () => import('@/views/projectManage/project/_projectId/newRecharge/index.vue')
            },
            {
                path: PageEnum.PROJECT_NEW_RECHARGE_REBATE,
                name: RouterNameEnum.PROJECT_NEW_RECHARGE_REBATE,
                meta: {
                    layout: 'dashboardLayout',
                    title: '充值返利'
                },
                component: () => import('@/views/projectManage/project/_projectId/newRecharge/create/index.vue')
            }
        ]
    ],
    [
        PermissionCodeEnum.RechargeCurrency,
        {
            path: PageEnum.PROJECT_RECHARGE_CURRENCY,
            name: RouterNameEnum.PROJECT_RECHARGE_CURRENCY,
            meta: {
                layout: 'dashboardLayout',
                title: '新建充值币'
            },
            component: () => import('@/views/projectManage/project/_projectId/recharge/currency/index.vue')
        }
    ],
    [
        PermissionCodeEnum.RechargeValue,
        {
            path: PageEnum.PROJECT_RECHARGE_VALUE,
            name: RouterNameEnum.PROJECT_RECHARGE_VALUE,
            meta: {
                layout: 'dashboardLayout',
                title: '新建充值面额'
            },
            component: () => import('@/views/projectManage/project/_projectId/recharge/value/index.vue')
        }
    ],
    [
        PermissionCodeEnum.PaymentType,
        {
            path: PageEnum.PROJECT_PAYS_METHODS,
            name: RouterNameEnum.PROJECT_PAYS_METHODS,
            meta: {
                layout: 'dashboardLayout',
                title: '支付方式'
            },
            component: () =>
                import(
                    '@/views/projectManage/project/_projectId/pay/methods/index.vue'
                    ),
        }
    ],
    [
        PermissionCodeEnum.PaymentSort,
        {
            path: PageEnum.PROJECT_PAYS_SORT,
            name: RouterNameEnum.PROJECT_PAYS_SORT,
            meta: {
                layout: 'dashboardLayout',
                title: '支付排序'
            },
            component: () => import('@/views/projectManage/project/_projectId/pay/sort/index.vue'),
        }
    ],
    [
        PermissionCodeEnum.PaymentConfig,
        {
            path: PageEnum.PROJECT_PAYS_CONFIG,
            name: RouterNameEnum.PROJECT_PAYS_CONFIG,
            meta: {
                layout: 'dashboardLayout',
                title: '支付配置'
            },
            component: () => import('@/views/projectManage/project/_projectId/pay/config/index.vue'),
        }
    ],
    [
        PermissionCodeEnum.Store,
        {
            path: PageEnum.PROJECT_SITE_BUILD,
            name: RouterNameEnum.PROJECT_SITE_BUILD,
            meta: {
                layout: 'dashboardLayout',
                title: '在线商城'
            },
            component: () => import('@/views/projectManage/project/_projectId/siteBuild/index.vue'),
        }
    ],
    [
        PermissionCodeEnum.UserGroup,
        {
            path: PageEnum.PROJECT_USER_GROUP,
            name: RouterNameEnum.PROJECT_USER_GROUP,
            meta: {
                layout: 'dashboardLayout',
                title: '用户群体'
            },
            component: () => import('@/views/projectManage/project/_projectId/userGroup/index.vue'),
        }
    ],
    [
        PermissionCodeEnum.AppWebhook,
        {
            path: PageEnum.PROJECT_WEBHOOKS_SETTING,
            name: RouterNameEnum.PROJECT_WEBHOOKS_SETTING,
            meta: {
                layout: 'dashboardLayout',
                title: 'webhooks'
            },
            component: () =>
                import('@/views/projectManage/project/_projectId/webhooksSetting/index.vue'),
        }
    ],
    [
        PermissionCodeEnum.PriceTemplate,
        {
            path: PageEnum.PROJECT_PRICE_TEMPLATE,
            name: RouterNameEnum.PROJECT_PRICE_TEMPLATE,
            meta: {
                layout: 'dashboardLayout',
                title: '定价模板'
            },
            component: () =>
                import('@/views/projectManage/project/_projectId/pricetemplate/index.vue'),
        }
    ],
    [
        PermissionCodeEnum.AutoUpdatePrice,
        {
            path: PageEnum.PROJECT_PRICE_UPDATE,
            name: RouterNameEnum.PROJECT_PRICE_UPDATE,
            meta: {
                layout: 'dashboardLayout',
                title: '自动更新价格'
            },
            component: () =>
                import('@/views/projectManage/project/_projectId/priceUpdate/index.vue'),
        }
    ],
    [
        PermissionCodeEnum.RiskManage,
        {
            path: PageEnum.PROJECT_RISK_MANAGER,
            name: RouterNameEnum.PROJECT_RISK_MANAGER,
            meta: {
                layout: 'dashboardLayout',
                title: '风险管理'
            },
            component: () => import('@/views/projectManage/project/_projectId/risk/index.vue'),
        },
    ],
    [
        PermissionCodeEnum.LanguageManage,
        {
            path: PageEnum.PROJECT_MULTI_LANGUAGE,
            name: RouterNameEnum.PROJECT_MULTI_LANGUAGE,
            meta: {
                layout: 'dashboardLayout',
                title: '多语言设置'
            },
            component: () => import('@/views/projectManage/project/_projectId/multiLanguage/index.vue')
        }
    ],
    [
        PermissionCodeEnum.Permission,
        {
            path: PageEnum.PERMISSION,
            name: RouterNameEnum.PERMISSION,
            redirect: PageEnum.PERMISSION_USERS,
            meta: {
                layout: 'dashboardLayout',
                title: '权限管理'
            },
            children: [
                {
                    path: PageEnum.PERMISSION_INVITE_USER,
                    name: RouterNameEnum.PERMISSION_INVITE_USER,
                    meta: {
                        layout: 'dashboardLayout',
                        title: '邀请用户'
                    },
                    component: () => import('@/views/permission/inviteUser/index.vue')
                }
            ]
        }
    ],
    [
        PermissionCodeEnum.PermissionUser,
        {
            path: PageEnum.PERMISSION_USERS,
            name: RouterNameEnum.PERMISSION_USERS,
            meta: {
                layout: 'dashboardLayout',
                title: '用户权限'
            },
            component: () => import('@/views/permission/users/index.vue')
        }
    ],
    [
        PermissionCodeEnum.PermissionMerchantSetting,
        {
            path: PageEnum.MERCHANT_SETTING,
            name: RouterNameEnum.MERCHANT_SETTING,
            meta: {
                layout: 'dashboardLayout',
                title: '商户设置'
            },
            component: () => import('@/views/merchantSetting/index.vue'),
        },
    ],
    [
        PermissionCodeEnum.PermissionCreateRole,
        {
            path: PageEnum.PERMISSION_CREATE_ROLE,
            name: RouterNameEnum.PERMISSION_CREATE_ROLE,
            meta: {
                layout: 'dashboardLayout',
                title: '创建角色'
            },
            component: () => import('@/views/permission/role/create/index.vue')
        }
    ],
    [
        PermissionCodeEnum.PermissionRoleDetail,
        {
            path: PageEnum.PERMISSION_ROLE_DETAIL,
            name: RouterNameEnum.PERMISSION_ROLE_DETAIL,
            meta: {
                layout: 'dashboardLayout',
                title: '角色详情'
            },
            component: () => import('@/views/permission/role/_id.vue')
        }
    ],
    [
        PermissionCodeEnum.PermissionUserDetail,
        {
            path: PageEnum.PERMISSION_USER_DETAIL,
            name: RouterNameEnum.PERMISSION_USER_DETAIL,
            meta: {
                layout: 'dashboardLayout',
                title: '用户详情'
            },
            component: () => import('@/views/permission/user/_id.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityXy,
        {
            path: PageEnum.ACTIVITY_XY,
            name: RouterNameEnum.ACTIVITY_XY,
            meta: {
                layout: 'dashboardLayout',
                title: '买X送Y'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/xy/index.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityXyCreate,
        {
            path: PageEnum.ACTIVITY_XY_CREATE,
            name: RouterNameEnum.ACTIVITY_XY_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '买X送Y配置'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/xy/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityXyEdit,
        {
            path: PageEnum.ACTIVITY_XY_CREATE,
            name: RouterNameEnum.ACTIVITY_XY_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '买X送Y配置'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/xy/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityAddUp,
        {
            path: PageEnum.ACTIVITY_ADD_UP,
            name: RouterNameEnum.ACTIVITY_ADD_UP,
            meta: {
                layout: 'dashboardLayout',
                title: '累充'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/addUp/index.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityAddUpReplay,
        {
            path: PageEnum.ACTIVITY_ADD_UP_REPLAY,
            name: RouterNameEnum.ACTIVITY_ADD_UP_REPLAY,
            meta: {
                layout: 'dashboardLayout',
                title: '累充复盘'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/addUp/replay.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityAddUpCreate,
        {
            path: PageEnum.ACTIVITY_ADD_UP_CREATE,
            name: RouterNameEnum.ACTIVITY_ADD_UP_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '累充配置'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/addUp/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityAddUpEdit,
        {
            path: PageEnum.ACTIVITY_ADD_UP_CREATE,
            name: RouterNameEnum.ACTIVITY_ADD_UP_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '累充配置'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/addUp/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityFirstBuy,
        {
            path: PageEnum.ACTIVITY_FIRST_BUY,
            name: RouterNameEnum.ACTIVITY_FIRST_BUY,
            meta: {
                layout: 'dashboardLayout',
                title: '商品首充'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/firstBuy/index.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityFirstBuyCreate,
        {
            path: PageEnum.ACTIVITY_FIRST_BUY_CREATE,
            name: RouterNameEnum.ACTIVITY_FIRST_BUY_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '商品首充'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/firstBuy/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityFirstBuyEdit,
        {
            path: PageEnum.ACTIVITY_FIRST_BUY_CREATE,
            name: RouterNameEnum.ACTIVITY_FIRST_BUY_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '商品首充'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/firstBuy/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityLimit,
        {
            path: PageEnum.ACTIVITY_LIMIT,
            name: RouterNameEnum.ACTIVITY_LIMIT,
            meta: {
                layout: 'dashboardLayout',
                title: '商品限购'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/limit/index.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityLimitCreate,
        {
            path: PageEnum.ACTIVITY_LIMIT_CREATE,
            name: RouterNameEnum.ACTIVITY_LIMIT_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '商品限购配置'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/limit/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityLimitEdit,
        {
            path: PageEnum.ACTIVITY_LIMIT_CREATE,
            name: RouterNameEnum.ACTIVITY_LIMIT_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '商品限购配置'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/limit/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityRecharge,
        {
            path: PageEnum.ACTIVITY_RECHARGE,
            name: RouterNameEnum.ACTIVITY_RECHARGE,
            meta: {
                layout: 'dashboardLayout',
                title: '充值折扣'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/recharge/index.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityRechargeCreate,
        {
            path: PageEnum.ACTIVITY_RECHARGE_CREATE,
            name: RouterNameEnum.ACTIVITY_RECHARGE_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '充值折扣'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/recharge/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityRechargeEdit,
        {
            path: PageEnum.ACTIVITY_RECHARGE_CREATE,
            name: RouterNameEnum.ACTIVITY_RECHARGE_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '充值折扣'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/recharge/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivitySignIn,
        {
            path: PageEnum.ACTIVITY_SIGN_IN,
            name: RouterNameEnum.ACTIVITY_SIGN_IN,
            meta: {
                layout: 'dashboardLayout',
                title: '签到'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/sign/index.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivitySignInCreate,
        {
            path: PageEnum.ACTIVITY_SIGN_IN_CREATE,
            name: RouterNameEnum.ACTIVITY_SIGN_IN_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '签到'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/sign/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivitySignInReplay,
        {
            path: PageEnum.ACTIVITY_SIGN_IN_REPLAY,
            name: RouterNameEnum.ACTIVITY_SIGN_IN_REPLAY,
            meta: {
                layout: 'dashboardLayout',
                title: '签到复盘'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/sign/replay.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivitySignInEdit,
        {
            path: PageEnum.ACTIVITY_SIGN_IN_CREATE,
            name: RouterNameEnum.ACTIVITY_SIGN_IN_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '签到'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/sign/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityConsecutive,
        {
            path: PageEnum.ACTIVITY_CONSECUTIVE,
            name: RouterNameEnum.ACTIVITY_CONSECUTIVE,
            meta: {
                layout: 'dashboardLayout',
                title: '连续充值'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/consecutive/index.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityConsecutiveCreate,
        {
            path: PageEnum.ACTIVITY_CONSECUTIVE_CREATE,
            name: RouterNameEnum.ACTIVITY_CONSECUTIVE_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '连续充值'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/consecutive/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityConsecutiveEdit,
        {
            path: PageEnum.ACTIVITY_CONSECUTIVE_CREATE,
            name: RouterNameEnum.ACTIVITY_CONSECUTIVE_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '连续充值'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/consecutive/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityConsecutiveReplay,
        {
            path: PageEnum.ACTIVITY_CONSECUTIVE_REPLAY,
            name: RouterNameEnum.ACTIVITY_CONSECUTIVE_REPLAY,
            meta: {
                layout: 'dashboardLayout',
                title: '连续充值复盘'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/consecutive/replay.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityLottery,
        {
            path: PageEnum.ACTIVITY_LOTTERY,
            name: RouterNameEnum.ACTIVITY_LOTTERY,
            meta: {
                layout: 'dashboardLayout',
                title: '抽奖'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/lottery/index.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityLotteryCreate,
        {
            path: PageEnum.ACTIVITY_LOTTERY_CREATE,
            name: RouterNameEnum.ACTIVITY_LOTTERY_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '抽奖'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/lottery/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityLotteryEdit,
        {
            path: PageEnum.ACTIVITY_LOTTERY_CREATE,
            name: RouterNameEnum.ACTIVITY_LOTTERY_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '抽奖'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/lottery/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityLotteryReplay,
        {
            path: PageEnum.ACTIVITY_LOTTERY_REPLAY,
            name: RouterNameEnum.ACTIVITY_LOTTERY_REPLAY,
            meta: {
                layout: 'dashboardLayout',
                title: '抽奖'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/lottery/replay.vue')
        }
    ],
    [
        PermissionCodeEnum.MonthCard,
        {
            path: PageEnum.MONTH_CARD,
            name: RouterNameEnum.MONTH_CARD,
            meta: {
                layout: 'dashboardLayout',
                title: '月/周卡'
            },
            component: () => import('@/views/projectManage/project/_projectId/monthCard/index.vue')
        }
    ],
    [
        PermissionCodeEnum.MonthCardEdit,
        {
            path: PageEnum.MONTH_CARD_EDIT,
            name: RouterNameEnum.MONTH_CARD_EDIT,
            meta: {
                layout: 'dashboardLayout',
                title: '月/周卡'
            },
            component: () => import('@/views/projectManage/project/_projectId/monthCard/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityFullDiscount,
        {
            path: PageEnum.ACTIVITY_FULL_DISCOUNT,
            name: RouterNameEnum.ACTIVITY_FULL_DISCOUNT,
            meta: {
                layout: 'dashboardLayout',
                title: '满额抽奖'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/fullDiscount/index.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityFullDiscountCreate,
        {
            path: PageEnum.ACTIVITY_FULL_DISCOUNT_CREATE,
            name: RouterNameEnum.ACTIVITY_FULL_DISCOUNT_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '满额抽奖'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/fullDiscount/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityFullDiscountReplay,
        {
            path: PageEnum.ACTIVITY_FULL_DISCOUNT_REPLAY,
            name: RouterNameEnum.ACTIVITY_FULL_DISCOUNT_REPLAY,
            meta: {
                layout: 'dashboardLayout',
                title: '满额抽奖复盘'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/fullDiscount/replay.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityCoupon,
        {
            path: PageEnum.ACTIVITY_COUPON,
            name: RouterNameEnum.ACTIVITY_COUPON,
            meta: {
                layout: 'dashboardLayout',
                title: '优惠券'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/coupon/index.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityCouponEdit,
        {
            path: PageEnum.ACTIVITY_COUPON_EDIT,
            name: RouterNameEnum.ACTIVITY_COUPON_EDIT,
            meta: {
                layout: 'dashboardLayout',
                title: '优惠券'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/coupon/create.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityCouponReplay,
        {
            path: PageEnum.ACTIVITY_COUPON_REPLAY,
            name: RouterNameEnum.ACTIVITY_COUPON_REPLAY,
            meta: {
                layout: 'dashboardLayout',
                title: '优惠券复盘'
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/coupon/replay.vue')
        }
    ],
    [
        PermissionCodeEnum.RechargeHistory,
        {
            path: PageEnum.RECHARGE_HISTORY,
            name: RouterNameEnum.RECHARGE_HISTORY,
            meta: {
                layout: 'dashboardLayout',
                title: '充值记录'
            },
            component: () => import('@/views/recharge/history/index.vue')
        }
    ],
    [
        PermissionCodeEnum.WithdrawCash,
        {
            path: PageEnum.WITHDRAW_CASH,
            name: RouterNameEnum.WITHDRAW_CASH,
            meta: {
                layout: 'dashboardLayout',
                title: '提现记录',
            },
            component: () => import('@/views/withdrawCash/index.vue')
        }
    ],
    [
        PermissionCodeEnum.ActivityInviteCode,
        {
            path: PageEnum.ACTIVITY_INVITE_CODE,
            name: RouterNameEnum.ACTIVITY_INVITE_CODE,
            meta: {
                layout: 'dashboardLayout',
                title: '邀请码',
            },
            component: () => import('@/views/projectManage/project/_projectId/activity/invite/index.vue')
        }
    ],
    [
        PermissionCodeEnum.OrderPurchase,
        {
            path: PageEnum.ORDER_PURCHASE,
            name: RouterNameEnum.ORDER_PURCHASE,
            meta: {
                layout: 'dashboardLayout',
                title: '订单购买',
                noBg: true
            },
            component: () => import('@/views/orderPurchase/index.vue')
        }
    ],
    [
        PermissionCodeEnum.OrderPurchaseHistory,
        {
            path: PageEnum.ORDER_PURCHASE_HISTORY,
            name: RouterNameEnum.ORDER_PURCHASE_HISTORY,
            meta: {
                layout: 'dashboardLayout',
                title: '订单购买记录',
            },
            component: () => import('@/views/orderPurchase/history/index.vue')
        }
    ],
    [
        PermissionCodeEnum.OrderPurchaseBill,
        {
            path: PageEnum.ORDER_PURCHASE_BILL,
            name: RouterNameEnum.ORDER_PURCHASE_BILL,
            meta: {
                layout: 'dashboardLayout',
                title: '订单购买账单',
            },
            component: () => import('@/views/orderPurchase/bill/index.vue')
        }
    ],
    [
        PermissionCodeEnum.PropsLibrary,
        {
            path: PageEnum.PROPS_LIBRARY,
            name: RouterNameEnum.PROPS_LIBRARY,
            meta: {
                layout: 'dashboardLayout',
                title: '道具库'
            },
            component: () => import('@/views/projectManage/project/_projectId/propsLibrary/index.vue')
        }
    ],
    [
        PermissionCodeEnum.VipCenter,
        {
            path: PageEnum.VIP_CENTER_RANK,
            name: RouterNameEnum.VIP_CENTER_RANK,
            meta: {
                layout: 'dashboardLayout',
                title: '会员概览',
                noBg: true
            },
            component: () => import('@/views/vip/index.vue')
        }
    ],
    [
        PermissionCodeEnum.VipCenterRankCreate,
        {
            path: PageEnum.VIP_CENTER_RANK_CREATE,
            name: RouterNameEnum.VIP_CENTER_RANK_CREATE,
            meta: {
                layout: 'dashboardLayout',
                title: '会员等级配置',
                noBg: true
            },
            component: () => import('@/views/vip/rank/create/index.vue')
        }
    ],
    [
        PermissionCodeEnum.VipCenterPoints,
        {
            path: PageEnum.VIP_CENTER_POINTS,
            name: RouterNameEnum.VIP_CENTER_POINTS,
            meta: {
                layout: 'dashboardLayout',
                title: '积分体系',
                noBg: true
            },
            component: () => import('@/views/vip/points/index.vue')
        }
    ],
    [
        PermissionCodeEnum.VipCenterOverview,
        {
            path: PageEnum.VIP_CENTER_OVERVIEW,
            name: RouterNameEnum.VIP_CENTER_OVERVIEW,
            meta: {
                layout: 'dashboardLayout',
                title: '会员概览'
            },
            component: () => import('@/views/vip/overview/index.vue')
        }
    ]
])

export const RoutePathPermissionMap = new Map([
    [
        PageEnum.BUSINESS,
        PermissionCodeEnum.Business
    ],
    [
        PageEnum.BUSINESS_DETAIL,
        PermissionCodeEnum.BusinessDetail
    ],
    [
        PageEnum.MERCHANT_SETTLEMENT,
        PermissionCodeEnum.Settlement
    ],
    [
        PageEnum.CHARGEBACK,
        PermissionCodeEnum.Chargeback
    ],
    [
        PageEnum.BILL,
        PermissionCodeEnum.Bill
    ],
    [
        PageEnum.BILL_DETAIL,
        PermissionCodeEnum.BillDetail
    ],
    [
        PageEnum.DATA_ANALYZE,
        PermissionCodeEnum.Analyze
    ],
    [
        PageEnum.PROJECT_ALL,
        PermissionCodeEnum.App
    ],
    [
        PageEnum.PERMISSION_USERS,
        PermissionCodeEnum.PermissionUser
    ],
    [
        PageEnum.PERMISSION_ROLE_DETAIL,
        PermissionCodeEnum.PermissionRoleDetail
    ],
    [
        PageEnum.PERMISSION_INVITE_USER,
        PermissionCodeEnum.PermissionUserInvite
    ],
    [
        PageEnum.PERMISSION_CREATE_ROLE,
        PermissionCodeEnum.PermissionCreateRole
    ],
    [
        PageEnum.PROJECT_CLASS,
        PermissionCodeEnum.VirtualGoods
    ],
    [
        PageEnum.PROJECT_CURRENCY,
        PermissionCodeEnum.VirtualCurrency
    ],
    [
        PageEnum.PROJECT_ACTIVITY,
        PermissionCodeEnum.VirtualActivity
    ],
    [
        PageEnum.PROJECT_ACTIVITY_REPLAY,
        PermissionCodeEnum.ActivityReplay
    ],
    [
        PageEnum.PROJECT_PAYS_METHODS,
        PermissionCodeEnum.PaymentType
    ],
    [
        PageEnum.PROJECT_PAYS_SORT,
        PermissionCodeEnum.PaymentSort
    ],
    [
        PageEnum.PROJECT_SITE_BUILD,
        PermissionCodeEnum.Store
    ],
    [
        PageEnum.PROJECT_USER_GROUP,
        PermissionCodeEnum.UserGroup
    ],
    [
        PageEnum.PROJECT_GENERAL_SETTING,
        PermissionCodeEnum.AppDetail
    ],
    [
        PageEnum.PROJECT_WEBHOOKS_SETTING,
        PermissionCodeEnum.AppWebhook
    ],
    [
        PageEnum.PROJECT_PRICE_TEMPLATE,
        PermissionCodeEnum.PriceTemplate
    ],
    [
        PageEnum.PROJECT_RECHARGE,
        PermissionCodeEnum.Recharge
    ],
    [
        PageEnum.PROJECT_RECHARGE_CURRENCY,
        PermissionCodeEnum.RechargeCurrency
    ],
    [
        PageEnum.PROJECT_RECHARGE_VALUE,
        PermissionCodeEnum.RechargeValue
    ],
    [
        PageEnum.PROJECT_PRICE_UPDATE,
        PermissionCodeEnum.AutoUpdatePrice
    ],
    [
        PageEnum.PROJECT_RISK_MANAGER,
        PermissionCodeEnum.RiskManage
    ],
    [
        PageEnum.PROJECT_MULTI_LANGUAGE,
        PermissionCodeEnum.LanguageManage
    ],
    [
        PageEnum.MERCHANT_SETTING,
        PermissionCodeEnum.PermissionMerchantSetting
    ],
    [
        PageEnum.PROJECT_GOODS_CLASSIFY,
        PermissionCodeEnum.GoodsClassify
    ],
    [
        PageEnum.PROJECT_GOODS_MANAGE,
        PermissionCodeEnum.GoodsManage
    ],
    [
        PageEnum.PROJECT_CONVERT_KEY,
        PermissionCodeEnum.ConvertKey
    ],
    [
        PageEnum.PROJECT_CONVERT_KEY_CREATE,
        PermissionCodeEnum.CoverKeyCreate
    ],
    [
        PageEnum.ACTIVITY_SIGN_IN_REPLAY,
        PermissionCodeEnum.ActivitySignInReplay
    ],
    [
        PageEnum.ACTIVITY_ADD_UP_REPLAY,
        PermissionCodeEnum.ActivityAddUpReplay
    ]
])


export const routesWhiteList: string[] = [
    PageEnum.BASE_HOME,
    PageEnum.RESET_PASSWORD_PAGE,
    PageEnum.BASE_LOGIN,
    PageEnum.SERVICE_PRIVACY,
    PageEnum.SERVICE_TERMS,
    PageEnum.INVITE_MEMBER,
    PageEnum.ERROR_PAGE,
    PageEnum.DOC,
    PageEnum.ABOUT,
    PageEnum.SERVICE_REFUND,
    PageEnum.SERVICE_PAYMENT
]