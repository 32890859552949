<template>
  <el-config-provider :locale="appElLocale">
    <a-config-provider :locale="appLocale">
      <DashboardLayout :ref="getDashboard" v-if="isDashboardLayout">
        <router-view :key="$route.fullPath" />
      </DashboardLayout>
      <LoginLayout v-else-if="isLoginLayout">
        <router-view :key="$route.fullPath" />
      </LoginLayout>
      <router-view v-else :key="$route.fullPath" />
    </a-config-provider>
  </el-config-provider>
</template>

<script setup name="App">
import { systemLanguage } from '@/plugins/i18n'
import { useI18n } from 'vue-i18n'
import elCn from 'element-plus/es/locale/lang/zh-cn'
import elTw from 'element-plus/es/locale/lang/zh-tw'
import elEn from 'element-plus/es/locale/lang/en'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import zhTW from 'ant-design-vue/es/locale/zh_TW'
import enUs from 'ant-design-vue/es/locale/en_US'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { computed, defineAsyncComponent, watch, ref } from 'vue'
import { useRoute } from 'vue-router'

const DashboardLayout = defineAsyncComponent(() => import('@/layouts/dashboardLayout.vue'))
const LoginLayout = defineAsyncComponent(() => import('@/layouts/loginLayout.vue'))
const { locale } = useI18n()
dayjs.locale('zh-cn')

const appLocale = computed(() => {
  if (locale.value === systemLanguage.en) {
    return enUs
  } else if (locale.value === systemLanguage.tw) {
    return zhTW
  } else {
    return zhCN
  }
})


const appElLocale = computed(() => {
  if (locale.value === systemLanguage.en) {
    return elEn
  } else if (locale.value === systemLanguage.tw) {
    return elTw
  } else {
    return elCn
  }
})

const $dashboard = ref(null)

const route = useRoute()

const getDashboard = (el) => {
  $dashboard.value = el
}

watch(() => route.path,() => {
  if ($dashboard.value) {
    $dashboard.value?.initMenu()
  }
},{ immediate: true })

const isDashboardLayout = computed(
  () => route?.meta?.layout === 'dashboardLayout'
)
const isLoginLayout = computed(
  () => route?.meta?.layout === 'loginLayout'
)
</script>
